// Configure dev and PROD for CICD
// FIXME: Implement using process.env for prod!

const dev = {
  s3: {
    // QAT Credentials as deployed in serverless for CRM case-records
    // Use west coast servers!!!!!
    REGION: "us-east-1",
    BUCKET: "lopezdp-uploads-api-dev-lopezattachmentsbucket-3s3l0b0t3mrv",
  },

  apiGateway: {
    REGION: "us-east-1",
    URL: "https://5hauupstpe.execute-api.us-east-1.amazonaws.com/dev",
  },

  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_x4PMVdDJg",
    APP_CLIENT_ID: "54kk05bgajqi13rebtk3c8njur",
    IDENTITY_POOL_ID: "us-east-1:2ef1a26d-8c05-4b87-9fd3-d2cb4926012b",
  },

  Places_API_KEY: "AIzaSyADJElWb4ZHSrfqko3De-53VoWPI9sCCz4",
  APPROVAL_CODE: "dlo_testing_only",
};

const prod = {
  s3: {
    // QAT Credentials as deployed in serverless for CRM case-records
    // Use west coast servers!!!!!
    REGION: "us-east-1",
    BUCKET: "lopezdp-uploads-api-dev-lopezattachmentsbucket-3s3l0b0t3mrv",
  },

  apiGateway: {
    REGION: "us-east-1",
    URL: "https://5hauupstpe.execute-api.us-east-1.amazonaws.com/dev",
  },

  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_x4PMVdDJg",
    APP_CLIENT_ID: "54kk05bgajqi13rebtk3c8njur",
    IDENTITY_POOL_ID: "us-east-1:2ef1a26d-8c05-4b87-9fd3-d2cb4926012b",
  },

  Places_API_KEY: "AIzaSyADJElWb4ZHSrfqko3De-53VoWPI9sCCz4",
  APPROVAL_CODE: "dlo_testing_only",
};

const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

// QAT Credentials as deployed in serverless for CRM case-records
export default {
  MAX_ATTACHMENT_SIZE: 5000000,

  ...config,
};
