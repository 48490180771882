import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Media from "react-media";
import Footer from "./Footer";
import "./NasaChallenger.css";

export default class NasaChallenger extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
		};
	}

	componentDidMount = () => {
		window.scrollTo(0, 0);
	};

	render() {
		return (
			<div className="view">
				{/* NOTE: 319px Small Mobile XS-Views!!!*/}
				<Media
					query="(min-width: 319px) and (max-width: 900px)"
					render={() => (
						<Container>
							<Container className="app-view-xs">
								<Container className="main-view-xs">
									<Jumbotron
										className="section-heading"
										fluid
									>
										<div className="iframe-containerBtc">
											<iframe
												src="/assets/machineLearningResearch/ChallengerORingData.html"
												allowFullScreen
												className="iframe-class1"
											></iframe>
										</div>
									</Jumbotron>
								</Container>
							</Container>

							{/* Need the Footer Menu to display new features */}
							<Footer />
						</Container>
					)}
				/>
				{/* NOTE: 1600px Desktop XL-Views!!!*/}
				<Media
					query="(min-width: 901px)"
					render={() => (
						<Container>
							<Container className="app-view">
								<Container className="main-view-research">
									<Jumbotron
										className="section-heading"
										fluid
									>
										<div className="iframe-containerBtc">
											<iframe
												src="/assets/machineLearningResearch/ChallengerORingData.html"
												allowFullScreen
												className="iframe-class1"
											></iframe>
										</div>
									</Jumbotron>
								</Container>
							</Container>

							{/* Need the Footer Menu to display new features */}
							<Footer />
						</Container>
					)}
				/>
			</div>
		);
	}
}
