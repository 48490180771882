import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Media from "react-media";
import ContactWidget from "../containers/ContactWidget";
import Footer from "./Footer";
import "./AiResearch.css";

export default class BlogArchive extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
		};
	}

	componentDidMount = () => {
		window.scrollTo(0, 0);
	};

	render() {
		return (
			<div className="view">
				{/* NOTE: 319px Small Mobile XS-Views!!!*/}
				<Media
					query="(min-width: 319px) and (max-width: 900px)"
					render={() => (
						<Container>
							<Container className="app-view-xs">
								<Container className="main-view-xs">
									<Link
										to="/software-revolt/mac-os-dev-setup"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Setup your MacOS development
													environment like a Pro!
												</h2>
												<h3>
													We will take advantage of
													iTerm2 and ZSH with Oh My
													ZSH on SublimeText3 to show
													you exactly how to setup
													your environment like a Sr.
													Software Engineer at NASA
													would.
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													The object of this series is
													to help you get setup on any
													machine; Windows, Linux, or
													MacOS quickly.
												</p>

												<p className="feat-abstract">
													It took me quite a while to
													understand the concept of
													setting up the development
													environment on the computer
													you want to work on, let
													alone that computer being a
													MacOS. I struggled with
													understanding the setup of a
													Bash prompt on a Windows
													machine, and what the
													difference was between the
													Window Command Line and a
													Linux Terminal. What the
													hell was Ubuntu anyway?? I
													sought out help from
													mentors, professors, and I
													even burned a few
													StackOverFlow accounts
													asking redundant and silly
													questions about the meaning
													of ls and why won't dir work
													on this bash prompt? The
													struggle was real, and yet I
													persisted.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									{/*}
									<Link
										to="/ai-machine-learning-research/btc-pricing-models"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h3>
													A Machine Learning Model
													using Python & TensorFlow
												</h3>
												<p className="feat-abstract">
													<em>Abstract & Task:</em> An
													implementation of a Machine
													Learning model using
													TensorFlow to describe the
													price of Bitcoin. The study
													quantified 30 predictors to
													develop a multiple linear
													regression to predict the
													market price of Bitcoin via
													Root Mean Square Error
													(RMSE) function. RMSE
													measures the error between
													observed and predicted data.
													The model resulted in an
													RMSE of 802.176, indicating
													that the loss accounts for
													4.1% of the error when
													compared to the observed
													target values. The model
													observed daily pricing and
													feature data to answer the
													question: which predictors
													have a significant impact on
													the price of Bitcoin?
													Consisting of 2,912
													observations the model
													explained 77.2% percent of
													the variation of the
													response variable.
													Corrections for
													multicollinearity took place
													by eliminating Variance
													Inflation Factors greater
													than 5 and selecting
													statistically-significant
													features whose P-Values were
													less than 0.05. The model
													suggested that the features
													associated with market
													demand, number of
													transactions, transaction
													confirmation times,
													transaction USD volume, and
													market demand measured using
													Google Search Trends data,
													account for the prices
													observed in the Bitcoin
													markets today. Faster
													confirmation times, demand
													as measured by daily Google
													searches, and transaction
													volume, explain 77.2% of the
													price of Bitcoin described
													by the R-Squared result.
												</p>
											</Container>
										</Jumbotron>
									</Link>





















    
    build-serverless-microservices
    reactjs-paypal-clone
    serverless-react-integration

									*/}
									<Link
										to="/software-revolt/setup-local-serverless-environment"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 1. Setup Local
													Serverless Development
												</h2>
												<h3>
													The aim of this tutorial is
													to deploy a simple
													application on AWS to share
													the joys of developing
													applications on the
													Serverless Framework with
													AWS. The application we will
													be walking you through
													includes a backend API
													service to handle basic CRUD
													operations built on
													something we call the DARN
													Technology Stack that
													includes the following tool
													set:
													<ul>
														<li>DynamoDB</li>
														<li>
															AWS Serverless
															Lambda
														</li>
														<li>React.js</li>
														<li>Node.js</li>
													</ul>
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													The object of this series is
													to help you get setup on any
													machine; Windows, Linux, or
													MacOS quickly.
												</p>

												<p className="feat-abstract">
													The article discusses the
													idea of a JavaScript Toolkit
													to more easily onboard new
													engineers onto a fast-moving
													team of developers to better
													and more effectively
													contribute to en enterprise
													level project in JavaScript.
													The tutorial reviews the
													proper installation of tools
													like `nvm`, `eslint`, code
													formatting, and the proper
													configuration of standard
													tools on your `local`
													machine and your IDE. One of
													the more difficult
													activities facing junior
													developers is understanding
													how to properly configure
													your machine locally and
													this tutorial will show the
													reader exactly how to start
													any project with ease and
													confidence.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/configure-serverless-backend"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 2. GoServerless on AWS
												</h2>
												<h3>
													Serverless programming and
													computing is a software
													architecture that enables an
													execution paradigm where the
													cloud service provider (AWS,
													GoogleCloud, Azure) is the
													entity responsible for
													running a piece of backend
													logic that you write in the
													form of a stateless
													function. In our case we are
													using AWS Lambda and the
													cloud provider you choose to
													run your stateless function,
													is responsible for the
													execution of your code in
													the cloud, and will
													dynamically allocate the
													resources needed to run your
													backend logic, by
													abstracting the deployment
													infrastructure for you, so
													that you can focus on
													developing your product
													instead of auto-scaling
													servers.
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													Since the serverless
													paradigm abstracts away the
													need for an engineer to
													configure the underlying
													physical infrastructure
													typical to the deployment of
													a modern day application, in
													what is known as the new
													Functions As A Service
													(FAAS) reality, the
													following are a few
													considerations that should
													be kept in mind while we
													proceed through the
													development of our Single
													Page Application:
													<ul>
														<li>
															Stateless Computing
														</li>
														<li>
															Serverless +
															Microservices
														</li>
														<li>Cold Starts</li>
													</ul>
												</p>

												<p className="feat-abstract">
													To deploy our demo
													application with a
													serverless backend to handle
													our business logic with
													independent functions
													deployed to AWS Lambda, we
													will need to configure
													Lambda and APIGateway to use
													the ServerlessFramework. The
													ServerlessFramework handles
													the configuration of our
													Lambda functions to use our
													code to respond to http
													requests triggered by
													APIGateway. The
													ServerlessFramework lets us
													use easy template files to
													programmatically describe
													the resources and
													infrastructure that we need
													AWS to provision for us, and
													on deployment, AWS
													CloudFormation does the job
													of instantiating the cloud
													based infrastructure that we
													call the serverless
													architecture on AWS. The
													serverless.yml file is the
													file that executes the
													explicit resources that we
													declare from within the
													ServerlessFramework, to tell
													AWS CloudFormation what we
													need from AWS to run our
													application.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/configure-infrastructure-as-code"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 3. Configure
													Infrastructure As Code
												</h2>
												<h3>
													The ServerlessFramework lets
													you describe the
													infrastructure that you want
													configured for your
													serverless + microservice
													based application logic. You
													can use template files in
													.yml or .json format to tell
													AWS CloudFormation what
													exact resources you need
													deployed on AWS to correctly
													run your application. The
													YAML or JSON-formatted files
													are the blueprints you
													design and architect to
													build your services with AWS
													resources. We can see that
													by using the AWS Template
													Anatomy to describe our
													infrastructure, that the
													templates on AWS
													CloudFormation will include
													a few major sections
													described in the template
													fragments shown below:
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													<ul>
														{" "}
														<li>
															Format Version
															(optional)
														</li>
														<li>
															Description
															(optional)
														</li>
														<li>
															Metadata (optional)
														</li>
														<li>
															Parameters
															(optional)
														</li>
														<li>
															Mappings (optional)
														</li>
														<li>
															Conditions
															(optional)
														</li>
														<li>
															Transform (optional)
														</li>
														<li>
															Resources (REQUIRED)
														</li>
														<li>
															Outputs (optional)
														</li>
													</ul>
												</p>

												<p className="feat-abstract">
													We also show you how to
													mock, or fake the input
													parameters for a specific
													event needed by our Lambda's
													with a `.json` file to be
													stored in a directory within
													the serverless +
													microservice project that we
													will use by executing the
													ServerlessFramework's invoke
													command. The invoke command
													will run your serverless +
													microservice code locally by
													emulating the AWS Lambda
													environment. Furthermore,
													the tutorial discusses how
													to implement Automated
													Testing with typical unit
													tests that will execute
													individual software modules
													or functions, in our case
													our unit tests will execute
													our Lambda functions on the
													AWS Cloud. When implementing
													your tests, you really want
													to try to make them useful,
													if not at least relevant to
													the goal of your
													application's business
													logic. You really want to
													take some time to think of
													any edge cases that your
													users may be inputting into
													your application to ensure
													that your application's user
													experience meets your user's
													needs and expectations. If
													you are working as part of a
													team, you really should
													collaborate with them on the
													different test cases that
													you should implement to
													mitigate any potential
													errors, that your users may
													confront.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/agile-code-review"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 4. Configure Backend
													CICD Pipelines
												</h2>
												<h3>
													By using an Agile
													development environment, we
													just want a process that we
													can use to iterate over a
													predefined Code Review
													workflow that will help us
													implement and merge new
													updates to our source code
													efficiently, transparently,
													and with close to zero
													downtime in the Wild. When a
													team member writes and
													implements a set of
													features, there should be
													someone, again, in my case
													Wilson, who will review the
													code you have implemented on
													a topic-branch in git after
													you create a Pull Request
													for your project lead to
													review your code. The Code
													Review process is an
													important part of your team
													workflow because it allows
													you to share the knowledge
													you gained from the
													implementation of the logic
													and functionality that
													defines the feature you will
													deploy. It also gives you a
													layer of quality assurance
													that enables your peers to
													contribute and provide
													insight into the feature you
													will deploy, and it allows
													new team members to learn
													from others on the team by
													taking ownership of a
													feature and implementing the
													logic the new feature needs
													so that it can be accepted
													by the project owner.
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													Throughout the remaining
													articles in this tutorial
													series we will indicate the
													newest updates that we have
													discovered and have had to
													evolve with using a tag like
													this: * `UPDATE`: This will
													discuss the update in
													question Moving forward with
													our project, and this
													tutorial, we can now take
													some time to discuss and
													understand the principles,
													practices, and benefits of
													adopting a DevOps mentality.
													We will also study and
													review concepts in
													Continuous Integration and
													Continuous Delivery and we
													will really start getting
													comfortable deploying
													enterprise ready software to
													the AWS Cloud. Just to make
													sure you are ready, we will
													review and get you
													comfortable with commiting
													your code to a Version
													Control repository on
													something like GitHub, and
													I'll show you how to setup a
													continuous integration
													server and integrate it with
													AWS DevOps tools like
													CodeBuild and CodePipeline.
												</p>

												<p className="feat-abstract">
													The idea is to apply
													software development
													practices like quality
													control, testing, and code
													reviews to infrastructure
													and feature deployment that
													can be rolled into
													production with little
													intervention and minimal
													risk. Transparency is
													prioritized so that every
													team member has a clear view
													at every stage of the
													development and deployment
													process from its
													implemetation by the dev
													team, all the way to the
													operations team that
													monitors and measures your
													application's resources and
													infrastructure deployed in
													production. The tutorial
													also discusses few recent
													updates to the AWS Lambda
													service that you should be
													aware of. The Node.js AWS
													Lambda runtime environment
													now supports Node.js
													v.10.14.1. In your
													serverless.yml file you
													should declare the runtime
													you will use as runtime:
													nodejs10.x to make sure that
													you can deploy your Lambda
													function correctly and with
													the latest supported
													features.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/build-serverless-microservices"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 5. Building Serverless
													+ MicroServices
												</h2>
												<h3>
													The article starts by
													defining the NoSQL tables
													that we will need to
													implement in AWS DynamoDB
													within an isolated
													environment, so that we can
													be sure to develop the
													appropriate data model
													needed for this specific
													serverless + microservice.
													Furthermore, in a
													microservice environment,
													each service will implement
													its own database. In the
													case of NoSQL, its own table
													(more on this to come).
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													The idea behind a
													microservice based
													architecture, for those of
													you not already in the know,
													is to be able to more easily
													maintain your code, and
													extend an infinite amount of
													features that you believe
													will save the world, in a
													decoupled environment that
													will allow you to build out
													new functionality without
													impacting the work of your
													team implementing their own
													versions of this
													application. Simply put, we
													just want to write code and
													develop services that only
													deal with one specific thing
													or task.
												</p>

												<p className="feat-abstract">
													We will use a loosely
													coupled architecture that
													makes it easy to develop,
													test, and deploy new
													features independently of
													each other, and to maintain
													more control over the
													stability of the system. No
													two services should rely on
													data from each other or any
													other source, nor should
													they know anything about the
													other's state. In a
													serverless + microservice
													environment, each
													microservice is going to
													have its own database, that
													will deal with the specific
													attributes that the service
													in question needs, to
													provide the correct response
													to any given request, while
													using the data it persists
													to its own data store.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/reactjs-paypal-clone"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 6. Building A ReactJS
													FrontEnd
												</h2>
												<h3>
													Now that we have a bunch of
													fancy backend logic on our
													AWS Cloud, we need to
													develop an engaging user
													interface that we can use to
													attract real humans to our
													latest get rich quick idea,
													we are going to continue to
													call it a digital Wallet to
													make mom, and our resumes,
													proud. Rather than get into
													the long history that led to
													React.js' role as the 'V' or
													the View in the
													Model-View-Controller design
													paradigm, we will spend some
													time implementing React.js
													so that you can learn by
													doing.
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													Since the Facebook Mafia is
													much smarter than us, we are
													just going to go ahead and
													use something called Create
													React App, or
													create-react-app for those
													who are more programatically
													inclined. All sarcasm and
													Dilbert Humor aside,
													React.js is a practical
													approach to another one of
													those esoteric software
													engineering questions that
													exist in an abundance of
													threads on the internet that
													scroll of into perpetuity.
													Implementing frontend
													component in React.js is
													effective and more
													productive approach that
													allows you to reuse code
													that you have written
													throughout your code. React
													takes advantage of a Virtual
													DOM that ituses to check
													against state changes to
													render dynamic UI elements
													faster than traditional MVC
													implementations.
												</p>

												<p className="feat-abstract">
													The most important part of
													any web development project
													is making sure to have a
													good set of tools that will
													let you create a beautiful
													user interface that your
													user can use to interact
													with your serverless
													backend. You can definitely
													spend your days implementing
													your own elements in
													JavaScript, HTML, and CSS
													and maybe create your own
													UIKit that you can market to
													the world to become famous,
													but for the purposes of this
													tutorial we are just going
													to go ahead and use the
													Bootstrap UI toolset via the
													React-Bootstrap library that
													we will import into each of
													our components as needed.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/serverless-react-integration"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 7. Serverless & React
													Integration with Multi-Step
													Registration
												</h2>
												<h3>
													Now that this application of
													ours has a login interface
													that our users can use to
													authenticate themselves, we
													are going to have to make
													sure that there is a
													mechanism in place to enable
													the permissions our users
													need to create and manage
													their transactions within
													our app. To properly
													complete these configuration
													steps we will need to be
													able to load our
													application's current state
													from our user's login
													session managed by Cognito,
													we'll need to implement a
													few redirects for proper
													login and logout
													functionality, and more
													importantly we always have
													to make sure that we are
													giving our users good
													feedback when logging in so
													that they can be sure that
													they are submitting the
													correct credentials.
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													Having declared an object
													that will hold the value of
													the authentication state
													that you need to pass into
													each rendered component used
													by your application, you now
													need to pass the object and
													its values into the Route
													component that is declared
													at the end of the render()
													function in your src/App.js
													file.
												</p>

												<p className="feat-abstract">
													Let's take a moment to talk
													about an advanced technique
													used in React.js as an
													emergent design pattern,
													because of its ability to
													allow developers to
													implement composite
													functions. Yes a function as
													an input to another
													function, or in the case of
													Higher Order Components in
													React.js, a function that
													accepts a Component as an
													argument to return a new
													Component that we
													canleverage to reuse
													Component logic. We need to
													use this concept to
													implement an HOC that will
													create a new Route for us
													that renders a new Child
													Component that will have the
													property values that have to
													be stored for use in the UI
													to deal with the appropriate
													state changes triggered by
													our users.
												</p>
											</Container>
										</Jumbotron>
									</Link>
								</Container>
								<Container className="aside-view-xs">
									<Container className="main-view-xs">
										<Jumbotron
											className="section-heading-xs"
											fluid
										>
											<ContactWidget vWidget={false} />
										</Jumbotron>
									</Container>
								</Container>
							</Container>

							{/* Need the Footer Menu to display new features */}
							<Footer />
						</Container>
					)}
				/>
				{/* NOTE: 1600px Desktop XL-Views!!!*/}
				<Media
					query="(min-width: 901px)"
					render={() => (
						<Container>
							<Container className="app-view">
								<Container className="main-view-ml">
									<Link
										to="/software-revolt/mac-os-dev-setup"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Setup your MacOS development
													environment like a Pro!
												</h2>
												<h3>
													We will take advantage of
													iTerm2 and ZSH with Oh My
													ZSH on SublimeText3 to show
													you exactly how to setup
													your environment like a Sr.
													Software Engineer at NASA
													would do.
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													The object of this series is
													to help you get setup on any
													machine; Windows, Linux, or
													MacOS quickly.
												</p>

												<p className="feat-abstract">
													It took me quite a while to
													understand the concept of
													setting up the development
													environment on the computer
													you want to work on, let
													alone that computer being a
													MacOS. I struggled with
													understanding the setup of a
													Bash prompt on a Windows
													machine, and what the
													difference was between the
													Window Command Line and a
													Linux Terminal. What the
													hell was Ubuntu anyway?? I
													sought out help from
													mentors, professors, and I
													even burned a few
													StackOverFlow accounts
													asking redundant and silly
													questions about the meaning
													of ls and why won't dir work
													on this bash prompt? The
													struggle was real, and yet I
													persisted.
												</p>
											</Container>
										</Jumbotron>
									</Link>
									{/*
									<Link
										to="/ai-machine-learning-research/btc-pricing-models"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h3>
													A Machine Learning Model
													using Python & TensorFlow
												</h3>
												<p className="feat-abstract">
													<em>Abstract & Task:</em> An
													implementation of a Machine
													Learning model using
													TensorFlow to describe the
													price of Bitcoin. The study
													quantified 30 predictors to
													develop a multiple linear
													regression to predict the
													market price of Bitcoin via
													Root Mean Square Error
													(RMSE) function. RMSE
													measures the error between
													observed and predicted data.
													The model resulted in an
													RMSE of 802.176, indicating
													that the loss accounts for
													4.1% of the error when
													compared to the observed
													target values. The model
													observed daily pricing and
													feature data to answer the
													question: which predictors
													have a significant impact on
													the price of Bitcoin?
													Consisting of 2,912
													observations the model
													explained 77.2% percent of
													the variation of the
													response variable.
													Corrections for
													multicollinearity took place
													by eliminating Variance
													Inflation Factors greater
													than 5 and selecting
													statistically-significant
													features whose P-Values were
													less than 0.05. The model
													suggested that the features
													associated with market
													demand, number of
													transactions, transaction
													confirmation times,
													transaction USD volume, and
													market demand measured using
													Google Search Trends data,
													account for the prices
													observed in the Bitcoin
													markets today. Faster
													confirmation times, demand
													as measured by daily Google
													searches, and transaction
													volume, explain 77.2% of the
													price of Bitcoin described
													by the R-Squared result.
												</p>
											</Container>
										</Jumbotron>
									</Link>
								*/}
									<Link
										to="/software-revolt/setup-local-serverless-environment"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 1. Setup Local
													Serverless Development
												</h2>
												<h3>
													The aim of this tutorial is
													to deploy a simple
													application on AWS to share
													the joys of developing
													applications on the
													Serverless Framework with
													AWS. The application we will
													be walking you through
													includes a backend API
													service to handle basic CRUD
													operations built on
													something we call the DARN
													Technology Stack that
													includes the following tool
													set:
													<ul>
														<li>DynamoDB</li>
														<li>
															AWS Serverless
															Lambda
														</li>
														<li>React.js</li>
														<li>Node.js</li>
													</ul>
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													The object of this series is
													to help you get setup on any
													machine; Windows, Linux, or
													MacOS quickly.
												</p>

												<p className="feat-abstract">
													The article discusses the
													idea of a JavaScript Toolkit
													to more easily onboard new
													engineers onto a fast-moving
													team of developers to better
													and more effectively
													contribute to en enterprise
													level project in JavaScript.
													The tutorial reviews the
													proper installation of tools
													like `nvm`, `eslint`, code
													formatting, and the proper
													configuration of standard
													tools on your `local`
													machine and your IDE. One of
													the more difficult
													activities facing junior
													developers is understanding
													how to properly configure
													your machine locally and
													this tutorial will show the
													reader exactly how to start
													any project with ease and
													confidence.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/configure-serverless-backend"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 2. GoServerless on AWS
												</h2>
												<h3>
													Serverless programming and
													computing is a software
													architecture that enables an
													execution paradigm where the
													cloud service provider (AWS,
													GoogleCloud, Azure) is the
													entity responsible for
													running a piece of backend
													logic that you write in the
													form of a stateless
													function. In our case we are
													using AWS Lambda and the
													cloud provider you choose to
													run your stateless function,
													is responsible for the
													execution of your code in
													the cloud, and will
													dynamically allocate the
													resources needed to run your
													backend logic, by
													abstracting the deployment
													infrastructure for you, so
													that you can focus on
													developing your product
													instead of auto-scaling
													servers.
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													Since the serverless
													paradigm abstracts away the
													need for an engineer to
													configure the underlying
													physical infrastructure
													typical to the deployment of
													a modern day application, in
													what is known as the new
													Functions As A Service
													(FAAS) reality, the
													following are a few
													considerations that should
													be kept in mind while we
													proceed through the
													development of our Single
													Page Application:
													<ul>
														<li>
															Stateless Computing
														</li>
														<li>
															Serverless +
															Microservices
														</li>
														<li>Cold Starts</li>
													</ul>
												</p>

												<p className="feat-abstract">
													To deploy our demo
													application with a
													serverless backend to handle
													our business logic with
													independent functions
													deployed to AWS Lambda, we
													will need to configure
													Lambda and APIGateway to use
													the ServerlessFramework. The
													ServerlessFramework handles
													the configuration of our
													Lambda functions to use our
													code to respond to http
													requests triggered by
													APIGateway. The
													ServerlessFramework lets us
													use easy template files to
													programmatically describe
													the resources and
													infrastructure that we need
													AWS to provision for us, and
													on deployment, AWS
													CloudFormation does the job
													of instantiating the cloud
													based infrastructure that we
													call the serverless
													architecture on AWS. The
													serverless.yml file is the
													file that executes the
													explicit resources that we
													declare from within the
													ServerlessFramework, to tell
													AWS CloudFormation what we
													need from AWS to run our
													application.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/configure-infrastructure-as-code"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 3. Configure
													Infrastructure As Code
												</h2>
												<h3>
													The ServerlessFramework lets
													you describe the
													infrastructure that you want
													configured for your
													serverless + microservice
													based application logic. You
													can use template files in
													.yml or .json format to tell
													AWS CloudFormation what
													exact resources you need
													deployed on AWS to correctly
													run your application. The
													YAML or JSON-formatted files
													are the blueprints you
													design and architect to
													build your services with AWS
													resources. We can see that
													by using the AWS Template
													Anatomy to describe our
													infrastructure, that the
													templates on AWS
													CloudFormation will include
													a few major sections
													described in the template
													fragments shown below:
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													<ul>
														{" "}
														<li>
															Format Version
															(optional)
														</li>
														<li>
															Description
															(optional)
														</li>
														<li>
															Metadata (optional)
														</li>
														<li>
															Parameters
															(optional)
														</li>
														<li>
															Mappings (optional)
														</li>
														<li>
															Conditions
															(optional)
														</li>
														<li>
															Transform (optional)
														</li>
														<li>
															Resources (REQUIRED)
														</li>
														<li>
															Outputs (optional)
														</li>
													</ul>
												</p>

												<p className="feat-abstract">
													We also show you how to
													mock, or fake the input
													parameters for a specific
													event needed by our Lambda's
													with a `.json` file to be
													stored in a directory within
													the serverless +
													microservice project that we
													will use by executing the
													ServerlessFramework's invoke
													command. The invoke command
													will run your serverless +
													microservice code locally by
													emulating the AWS Lambda
													environment. Furthermore,
													the tutorial discusses how
													to implement Automated
													Testing with typical unit
													tests that will execute
													individual software modules
													or functions, in our case
													our unit tests will execute
													our Lambda functions on the
													AWS Cloud. When implementing
													your tests, you really want
													to try to make them useful,
													if not at least relevant to
													the goal of your
													application's business
													logic. You really want to
													take some time to think of
													any edge cases that your
													users may be inputting into
													your application to ensure
													that your application's user
													experience meets your user's
													needs and expectations. If
													you are working as part of a
													team, you really should
													collaborate with them on the
													different test cases that
													you should implement to
													mitigate any potential
													errors, that your users may
													confront.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/agile-code-review"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 4. Configure Backend
													CICD Pipelines
												</h2>
												<h3>
													By using an Agile
													development environment, we
													just want a process that we
													can use to iterate over a
													predefined Code Review
													workflow that will help us
													implement and merge new
													updates to our source code
													efficiently, transparently,
													and with close to zero
													downtime in the Wild. When a
													team member writes and
													implements a set of
													features, there should be
													someone, again, in my case
													Wilson, who will review the
													code you have implemented on
													a topic-branch in git after
													you create a Pull Request
													for your project lead to
													review your code. The Code
													Review process is an
													important part of your team
													workflow because it allows
													you to share the knowledge
													you gained from the
													implementation of the logic
													and functionality that
													defines the feature you will
													deploy. It also gives you a
													layer of quality assurance
													that enables your peers to
													contribute and provide
													insight into the feature you
													will deploy, and it allows
													new team members to learn
													from others on the team by
													taking ownership of a
													feature and implementing the
													logic the new feature needs
													so that it can be accepted
													by the project owner.
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													Throughout the remaining
													articles in this tutorial
													series we will indicate the
													newest updates that we have
													discovered and have had to
													evolve with using a tag like
													this: * `UPDATE`: This will
													discuss the update in
													question Moving forward with
													our project, and this
													tutorial, we can now take
													some time to discuss and
													understand the principles,
													practices, and benefits of
													adopting a DevOps mentality.
													We will also study and
													review concepts in
													Continuous Integration and
													Continuous Delivery and we
													will really start getting
													comfortable deploying
													enterprise ready software to
													the AWS Cloud. Just to make
													sure you are ready, we will
													review and get you
													comfortable with commiting
													your code to a Version
													Control repository on
													something like GitHub, and
													I'll show you how to setup a
													continuous integration
													server and integrate it with
													AWS DevOps tools like
													CodeBuild and CodePipeline.
												</p>

												<p className="feat-abstract">
													The idea is to apply
													software development
													practices like quality
													control, testing, and code
													reviews to infrastructure
													and feature deployment that
													can be rolled into
													production with little
													intervention and minimal
													risk. Transparency is
													prioritized so that every
													team member has a clear view
													at every stage of the
													development and deployment
													process from its
													implemetation by the dev
													team, all the way to the
													operations team that
													monitors and measures your
													application's resources and
													infrastructure deployed in
													production. The tutorial
													also discusses few recent
													updates to the AWS Lambda
													service that you should be
													aware of. The Node.js AWS
													Lambda runtime environment
													now supports Node.js
													v.10.14.1. In your
													serverless.yml file you
													should declare the runtime
													you will use as runtime:
													nodejs10.x to make sure that
													you can deploy your Lambda
													function correctly and with
													the latest supported
													features.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/build-serverless-microservices"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 5. Building Serverless
													+ MicroServices
												</h2>
												<h3>
													The article starts by
													defining the NoSQL tables
													that we will need to
													implement in AWS DynamoDB
													within an isolated
													environment, so that we can
													be sure to develop the
													appropriate data model
													needed for this specific
													serverless + microservice.
													Furthermore, in a
													microservice environment,
													each service will implement
													its own database. In the
													case of NoSQL, its own table
													(more on this to come).
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													The idea behind a
													microservice based
													architecture, for those of
													you not already in the know,
													is to be able to more easily
													maintain your code, and
													extend an infinite amount of
													features that you believe
													will save the world, in a
													decoupled environment that
													will allow you to build out
													new functionality without
													impacting the work of your
													team implementing their own
													versions of this
													application. Simply put, we
													just want to write code and
													develop services that only
													deal with one specific thing
													or task.
												</p>

												<p className="feat-abstract">
													We will use a loosely
													coupled architecture that
													makes it easy to develop,
													test, and deploy new
													features independently of
													each other, and to maintain
													more control over the
													stability of the system. No
													two services should rely on
													data from each other or any
													other source, nor should
													they know anything about the
													other's state. In a
													serverless + microservice
													environment, each
													microservice is going to
													have its own database, that
													will deal with the specific
													attributes that the service
													in question needs, to
													provide the correct response
													to any given request, while
													using the data it persists
													to its own data store.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/reactjs-paypal-clone"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 6. Building A ReactJS
													FrontEnd
												</h2>
												<h3>
													Now that we have a bunch of
													fancy backend logic on our
													AWS Cloud, we need to
													develop an engaging user
													interface that we can use to
													attract real humans to our
													latest get rich quick idea,
													we are going to continue to
													call it a digital Wallet to
													make mom, and our resumes,
													proud. Rather than get into
													the long history that led to
													React.js' role as the 'V' or
													the View in the
													Model-View-Controller design
													paradigm, we will spend some
													time implementing React.js
													so that you can learn by
													doing.
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													Since the Facebook Mafia is
													much smarter than us, we are
													just going to go ahead and
													use something called Create
													React App, or
													create-react-app for those
													who are more programatically
													inclined. All sarcasm and
													Dilbert Humor aside,
													React.js is a practical
													approach to another one of
													those esoteric software
													engineering questions that
													exist in an abundance of
													threads on the internet that
													scroll of into perpetuity.
													Implementing frontend
													component in React.js is
													effective and more
													productive approach that
													allows you to reuse code
													that you have written
													throughout your code. React
													takes advantage of a Virtual
													DOM that ituses to check
													against state changes to
													render dynamic UI elements
													faster than traditional MVC
													implementations.
												</p>

												<p className="feat-abstract">
													The most important part of
													any web development project
													is making sure to have a
													good set of tools that will
													let you create a beautiful
													user interface that your
													user can use to interact
													with your serverless
													backend. You can definitely
													spend your days implementing
													your own elements in
													JavaScript, HTML, and CSS
													and maybe create your own
													UIKit that you can market to
													the world to become famous,
													but for the purposes of this
													tutorial we are just going
													to go ahead and use the
													Bootstrap UI toolset via the
													React-Bootstrap library that
													we will import into each of
													our components as needed.
												</p>
											</Container>
										</Jumbotron>
									</Link>

									<Link
										to="/software-revolt/serverless-react-integration"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Part 7. Serverless & React
													Integration with Multi-Step
													Registration
												</h2>
												<h3>
													Now that this application of
													ours has a login interface
													that our users can use to
													authenticate themselves, we
													are going to have to make
													sure that there is a
													mechanism in place to enable
													the permissions our users
													need to create and manage
													their transactions within
													our app. To properly
													complete these configuration
													steps we will need to be
													able to load our
													application's current state
													from our user's login
													session managed by Cognito,
													we'll need to implement a
													few redirects for proper
													login and logout
													functionality, and more
													importantly we always have
													to make sure that we are
													giving our users good
													feedback when logging in so
													that they can be sure that
													they are submitting the
													correct credentials.
												</h3>
												<p className="feat-abstract">
													<em>Series Objective:</em>{" "}
													Having declared an object
													that will hold the value of
													the authentication state
													that you need to pass into
													each rendered component used
													by your application, you now
													need to pass the object and
													its values into the Route
													component that is declared
													at the end of the render()
													function in your src/App.js
													file.
												</p>

												<p className="feat-abstract">
													Let's take a moment to talk
													about an advanced technique
													used in React.js as an
													emergent design pattern,
													because of its ability to
													allow developers to
													implement composite
													functions. Yes a function as
													an input to another
													function, or in the case of
													Higher Order Components in
													React.js, a function that
													accepts a Component as an
													argument to return a new
													Component that we
													canleverage to reuse
													Component logic. We need to
													use this concept to
													implement an HOC that will
													create a new Route for us
													that renders a new Child
													Component that will have the
													property values that have to
													be stored for use in the UI
													to deal with the appropriate
													state changes triggered by
													our users.
												</p>
											</Container>
										</Jumbotron>
									</Link>
								</Container>
								<Container className="aside-view-ml">
									<Jumbotron
										className="section-heading"
										fluid
									>
										<ContactWidget vWidget={false} />
									</Jumbotron>
								</Container>
							</Container>

							{/* Need the Footer Menu to display new features */}
							<Footer />
						</Container>
					)}
				/>
			</div>
		);
	}
}
