import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Media from "react-media";
import Footer from "./Footer";
import "./BtcPriceModel.css";
import "./MacOsDevSetup.css";
import SetupMacOS4 from "../revolt-blog/March2021/Part4. HowToReviewServiceToConfigureCICDpipeline.md";
import ReactMarkdown from "react-markdown/with-html";

export default class MacOsDevSetUp extends Component {
	constructor(props) {
		super(props);

		this.state = { terms: null };
	}

	componentWillMount() {
		fetch(SetupMacOS4)
			.then((response) => response.text())
			.then((text) => {
				this.setState({ terms: text });
			});
	}

	render() {
		return (
			<div className="view">
				{/* NOTE: 319px Small Mobile XS-Views!!!*/}
				<Media
					query="(min-width: 319px) and (max-width: 900px)"
					render={() => (
						<Container>
							<Container className="app-view-xs">
								<Container className="main-view-xs">
									<Jumbotron
										className="section-heading"
										fluid
									>
										<div className="markdown-container">
											<ReactMarkdown
												source={this.state.terms}
												escapeHtml={false}
											/>
										</div>
									</Jumbotron>
								</Container>
							</Container>

							{/* Need the Footer Menu to display new features */}
							<Footer />
						</Container>
					)}
				/>
				{/* NOTE: 1600px Desktop XL-Views!!!*/}
				<Media
					query="(min-width: 901px)"
					render={() => (
						<Container>
							<Container className="app-view">
								<Container className="main-view-research">
									<Jumbotron
										className="section-heading"
										fluid
									>
										<div className="markdown-container">
											<ReactMarkdown
												source={this.state.terms}
												escapeHtml={false}
											/>
										</div>
									</Jumbotron>
								</Container>
							</Container>

							{/* Need the Footer Menu to display new features */}
							<Footer />
						</Container>
					)}
				/>
			</div>
		);
	}
}
