import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./containers/Home";
import ContactWidget from "./containers/ContactWidget";
import AiResearch from "./components/AiResearch";
import NasaChallenger from "./components/NasaChallenger";
import BtcPriceModel from "./components/BtcPriceModel";
import BlogArchive from "./components/BlogArchive";
import MacOsDevSetup from "./components/MacOsDevSetup";
import Part1ServerlessSetup from "./components/Part1ServerlessSetup";
import Part2ConfigureServerlessBackend from "./components/Part2ConfigureServerlessBackend";
import Part3ConfigureIacMocks from "./components/Part3ConfigureIacMocks";
import Part4AgileCodeReview from "./components/Part4AgileCodeReview";
import Part5BuildServerlessMicroServices from "./components/Part5BuildServerlessMicroServices";
import Part6ReactJsFrontendClone from "./components/Part6ReactJsFrontendClone";
import Part7ServerlessReactIntegration from "./components/Part7ServerlessReactIntegration";
import Page404 from "./components/Page404";

import AppliedRoute from "./components/AppliedRoute";
//import TermsAndConditions from "./components/TermsAndConditions";
//import PrivacyPolicy from "./components/PrivacyPolicy";
//import Support from "./components/Support";
//import AuthenticatedRoute from "./components/AuthenticatedRoute";
//import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) => (
  <Switch>
    {/* This is our home page route for the main landing page to the app */}
    <AppliedRoute path="/" exact component={Home} props={childProps} />

    <AppliedRoute
      path="/ai-machine-learning-research"
      exact
      component={AiResearch}
      props={childProps}
    />

    <AppliedRoute
      path="/ai-machine-learning-research/nasa-challenger"
      exact
      component={NasaChallenger}
      props={childProps}
    />

    <AppliedRoute
      path="/ai-machine-learning-research/btc-pricing-models"
      exact
      component={BtcPriceModel}
      props={childProps}
    />

    <AppliedRoute
      path="/software-revolt"
      exact
      component={BlogArchive}
      props={childProps}
    />

    <AppliedRoute
      path="/software-revolt/mac-os-dev-setup"
      exact
      component={MacOsDevSetup}
      props={childProps}
    />

    <AppliedRoute
      path="/software-revolt/setup-local-serverless-environment"
      exact
      component={Part1ServerlessSetup}
      props={childProps}
    />

    <AppliedRoute
      path="/software-revolt/configure-serverless-backend"
      exact
      component={Part2ConfigureServerlessBackend}
      props={childProps}
    />

    <AppliedRoute
      path="/software-revolt/configure-infrastructure-as-code"
      exact
      component={Part3ConfigureIacMocks}
      props={childProps}
    />

    <AppliedRoute
      path="/software-revolt/agile-code-review"
      exact
      component={Part4AgileCodeReview}
      props={childProps}
    />

    <AppliedRoute
      path="/software-revolt/build-serverless-microservices"
      exact
      component={Part5BuildServerlessMicroServices}
      props={childProps}
    />

    <AppliedRoute
      path="/software-revolt/reactjs-paypal-clone"
      exact
      component={Part6ReactJsFrontendClone}
      props={childProps}
    />

    <AppliedRoute
      path="/software-revolt/serverless-react-integration"
      exact
      component={Part7ServerlessReactIntegration}
      props={childProps}
    />

    <AppliedRoute
      path="/contact-me"
      exact
      component={ContactWidget}
      props={childProps}
    />

    {/* This is the terms-conditions Route
                                   <AppliedRoute path="/terms-conditions"
                                     exact
                                     component={ TermsAndConditions }
                                     props={ childProps } />

                                   { /* This is the privacy-policy Route 
                                   <AppliedRoute path="/privacy-policy"
                                     exact
                                     component={ PrivacyPolicy }
                                     props={ childProps } />

                                   { /* This is the support Route 
                                   <AppliedRoute path="/support"
                                     exact
                                     component={ Support }
                                     props={ childProps } />

                                   { /* This route will catch all unmatched routes && MUST BE LAST!!! */}
    <Route component={Page404} />
  </Switch>
);
