import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Media from "react-media";
import Button from "react-bootstrap/Button";
// import { LinkContainer } from 'react-router-bootstrap';
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      userAccessRole: "",
    };
  }

  // TODO: dev branch change just to get on cicd for now
  // TODO: test branch change just to get on cicd for now
  // TODO: demo branch change just to get on cicd for now
  // Use componentDidMount() to load the user session with Amplify
  async componentDidMount() {
    window.scrollTo(0, 0);
    try {
      // returns a promise, rest of app will wait for this response
      // from amplify auth with cognito
      //await Auth.currentSession();
      // if 200 OK from above with session info then execute next
      this.userHasAuthenticated(false);

      // if OK pull role from cognito custom attribute and store in state
      //this.userHasPermission("admin");
    } catch (err) {
      if (err !== "No current user") {
        // eslint-disable-next-line
        alert("Test");
      }
    }
    // loading user session is asyn process, we need to make sure
    // that our app does not change state when it first loads
    // we wait to render until isAuthenticating is false
    this.setState({
      isAuthenticating: false,
    });
  }

  /*
  userHasPermission = accessRole => {
    this.setState({
      userAccessRole: accessRole
    });
  }
*/

  userHasAuthenticated = (authenticated) => {
    this.setState({
      isAuthenticated: authenticated,
    });
  };

  // eslint-disable-next-line
  handleLogout = async (event) => {
    // returns a promise that will clear user session for logout
    await Auth.signOut();
    this.userHasAuthenticated(false);
    // use history.push from react-router4 to redirect
    // user during login flow
    this.props.history.push("/signin");
  };

  render() {
    const childProps = {
      // below is authentication provided by cognito
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      // Below is permissions available and passed as props
      // authorization!!
      //userAccessRole: this.state.userAccessRole,
      //userHasPermission: this.userHasPermission
      vWidget: true,
    };

    return (
      !this.state.isAuthenticating && (
        <Container>
          <Media
            query="(min-width: 319px) and (max-width: 899px)"
            render={() => (
              <div className="App container">
                <Navbar
                  className="app-navi-bar"
                  bg="light"
                  variant="light"
                  expand="lg"
                >
                  <Row className="brand-sm">
                    <Col className="profile-img-col">
                      <Navbar.Brand as={NavLink} to="/">
                        <Image
                          src="https://cdn-assets.nativestack.io/images/branding/NS.Icon.Light.png"
                          className="App-logo-sm"
                        />
                      </Navbar.Brand>
                    </Col>
                  </Row>

                  <Row className="brand-sm">
                    <Col className="profile-desc-col">
                      <section>
                        <h1 className="engineer-name-xs">David P. Lopez</h1>
                      </section>
                    </Col>
                  </Row>

                  <Row className="brand-sm">
                    <Col className="profile-title-col">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-bottom">
                            <strong>Systems Architect</strong>
                          </Tooltip>
                        }
                      >
                        <section>
                          <h2 className="job-description">
                            Sr. Software Engineer
                          </h2>
                        </section>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                  <Row className="social-media-icons">
                    <Nav.Link href="https://github.com/lopezdp">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id={"tooltip-bottom"}>
                            <strong>GitHub</strong>
                          </Tooltip>
                        }
                      >
                        <Image
                          alt="GitHub"
                          src="/img/SocialMediaIcons/GitHub.png"
                          className="social-icon"
                        />
                      </OverlayTrigger>
                    </Nav.Link>

                    <Nav.Link href="https://stackoverflow.com/users/3879359/lopezdp?tab=profile">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id={"tooltip-bottom"}>
                            <strong>StackOverflow</strong>
                          </Tooltip>
                        }
                      >
                        <Image
                          alt="StackOverflow"
                          src="/img/SocialMediaIcons/StackOverflow.png"
                          className="social-icon"
                        />
                      </OverlayTrigger>
                    </Nav.Link>

                    <Nav.Link href="https://www.behance.net/lopezdp">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id={"tooltip-bottom"}>
                            <strong>Behance</strong>
                          </Tooltip>
                        }
                      >
                        <Image
                          alt="Behance"
                          src="/img/SocialMediaIcons/Behance.png"
                          className="social-icon"
                        />
                      </OverlayTrigger>
                    </Nav.Link>

                    <Nav.Link href="https://profile.codersrank.io/user/lopezdp">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id={"tooltip-bottom"}>
                            <strong>Coder's Rank</strong>
                          </Tooltip>
                        }
                      >
                        <Image
                          alt="Coder's Rank"
                          src="/img/SocialMediaIcons/CoderRank.png"
                          className="social-icon"
                        />
                      </OverlayTrigger>
                    </Nav.Link>

                    <Nav.Link href="https://www.spooncast.net/us/profile/1100065713/cast?t=live">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id={"tooltip-bottom"}>
                            <strong>Spoon Radio</strong>
                          </Tooltip>
                        }
                      >
                        <Image
                          alt="Spoon"
                          src="/img/SocialMediaIcons/Spoon.png"
                          className="social-icon"
                        />
                      </OverlayTrigger>
                    </Nav.Link>

                    <Nav.Link href="https://twitch.com/lopezdp">
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-bottom">
                            <strong>Twitch</strong>
                          </Tooltip>
                        }
                      >
                        <Image
                          alt="Twitch"
                          src="/img/SocialMediaIcons/Twitch.png"
                          className="social-icon"
                        />
                      </OverlayTrigger>
                    </Nav.Link>
                  </Row>

                  <NavLink
                    to={{ pathname: "https://calendar.ecomindustries.io" }}
                    target="_blank"
                  >
                    <Row className="brand-sm">
                      <Col className="profile-btn-col">
                        <Image
                          alt="Gmail"
                          src="/img/LandingPageImages/SendMessageIcon.png"
                          className="btn-icon"
                        />
                        <strong>Contact Me</strong>
                      </Col>
                    </Row>
                  </NavLink>
                </Navbar>

                <Routes childProps={childProps} />
              </div>
            )}
          />

          <Media
            query="(min-width: 900px)"
            render={() => (
              <div className="App container">
                <Navbar
                  className="app-navi-bar"
                  bg="light"
                  variant="light"
                  expand="lg"
                >
                  <Row className="brand-lg">
                    <Col className="profile-img-col-lg">
                      <Navbar.Brand as={NavLink} to="/">
                        <Image
                          src="https://cdn-assets.nativestack.io/images/branding/NS.Icon.Light.png"
                          className="App-logo-lg"
                        />
                      </Navbar.Brand>
                    </Col>

                    <Col className="profile-name-col-lg">
                      <Row className="brand-lg-vert1">
                        <Col className="profile-desc-col-lg">
                          <section>
                            <h1 className="engineer-name">David P. Lopez</h1>
                          </section>
                        </Col>
                      </Row>

                      <Row className="brand-lg-vert2">
                        <Col className="profile-title-col">
                          <OverlayTrigger
                            key="bottom"
                            placement="left"
                            overlay={
                              <Tooltip id="tooltip-bottom">
                                <strong>Systems Architect</strong>
                              </Tooltip>
                            }
                          >
                            <section>
                              <h2 className="job-description">
                                Sr. Software Engineer
                              </h2>
                            </section>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </Col>

                    <Col className="profile-contact-col-lg">
                      <NavLink
                        to={{ pathname: "https://calendar.ecomindustries.io" }}
                        target="_blank"
                      >
                        <Row className="brand-lg-contact-vert1">
                          <Col className="profile-btn-col">
                            <Image
                              alt="Gmail"
                              src="/img/LandingPageImages/SendMessageIcon.png"
                              className="btn-icon"
                            />
                            <strong>Contact Me</strong>
                          </Col>
                        </Row>
                      </NavLink>
                      <Row className="social-media-icons-lg brand-lg-contact-vert2">
                        <Nav.Link href="https://github.com/lopezdp">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-bottom"}>
                                <strong>GitHub</strong>
                              </Tooltip>
                            }
                          >
                            <Image
                              alt="GitHub"
                              src="/img/SocialMediaIcons/GitHub.png"
                              className="social-icon"
                            />
                          </OverlayTrigger>
                        </Nav.Link>

                        <Nav.Link href="https://stackoverflow.com/users/story/3879359">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-bottom"}>
                                <strong>StackOverflow</strong>
                              </Tooltip>
                            }
                          >
                            <Image
                              alt="StackOverflow"
                              src="/img/SocialMediaIcons/StackOverflow.png"
                              className="social-icon"
                            />
                          </OverlayTrigger>
                        </Nav.Link>

                        <Nav.Link href="https://www.behance.net/lopezdp">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-bottom"}>
                                <strong>Behance</strong>
                              </Tooltip>
                            }
                          >
                            <Image
                              alt="Behance"
                              src="/img/SocialMediaIcons/Behance.png"
                              className="social-icon"
                            />
                          </OverlayTrigger>
                        </Nav.Link>

                        <Nav.Link href="https://profile.codersrank.io/user/lopezdp">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-bottom"}>
                                <strong>Coder's Rank</strong>
                              </Tooltip>
                            }
                          >
                            <Image
                              alt="Coder's Rank"
                              src="/img/SocialMediaIcons/CoderRank.png"
                              className="social-icon"
                            />
                          </OverlayTrigger>
                        </Nav.Link>

                        <Nav.Link href="https://www.spooncast.net/us/profile/1100065713/cast?t=live">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-bottom"}>
                                <strong>SpoonCast Radio</strong>
                              </Tooltip>
                            }
                          >
                            <Image
                              alt="SpoonCast"
                              src="/img/SocialMediaIcons/Spoon.png"
                              className="social-icon"
                            />
                          </OverlayTrigger>
                        </Nav.Link>

                        <Nav.Link href="https://twitch.com/lopezdp">
                          <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                              <Tooltip id={"tooltip-bottom"}>
                                <strong>Twitch</strong>
                              </Tooltip>
                            }
                          >
                            <Image
                              alt="Twitch"
                              src="/img/SocialMediaIcons/Twitch.png"
                              className="social-icon"
                            />
                          </OverlayTrigger>
                        </Nav.Link>
                      </Row>
                    </Col>
                  </Row>
                </Navbar>

                <Routes childProps={childProps} />
              </div>
            )}
          />
        </Container>
      )
    );
  }
}

// export with a HOC to give app access to router props
// access the history object's props and the closest
// <Routes>'s match withRouter().
export default withRouter(App);
//export default App;
