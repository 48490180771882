import React, { Component } from "react";
import "./Testimonial.css";

import Coverflow from "react-coverflow";
import { StyleRoot } from "radium";

export default class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <StyleRoot>
                <Coverflow
                    displayQuantityOfSide={2}
                    currentFigureScale={2.4}
                    otherFigureScale={0}
                    navigation
                    infiniteScroll
                    enableHeading={false}
                    media={{
                        "@media (max-width: 450px)": {
                            width: "100%",
                            height: "400px",
                        },
                        "@media (min-width: 451px) and (max-width: 900px)": {
                            width: "100%",
                            height: "450px",
                        },
                        "@media (min-width: 901px) and (max-width: 1365px)": {
                            width: "100%",
                            height: "600px",
                        },
                        "@media (min-width: 1366px)": {
                            width: "100%",
                            height: "550px",
                        },
                    }}
                >
                    <img
                        src="/img/ReviewImages/2019.4.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/2019.5.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/2019.6.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/2021.2.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/2021.1.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/2019.7.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/2019.8.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/Multi1.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/Multi2.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/Multi3.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/2019.1.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/2019.2.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/2019.3.png"
                        alt="5 Star Review"
                        data-action="https://www.upwork.com/fl/davidlopez7"
                        className="review-image"
                    />
                    <img
                        src="/img/ReviewImages/TP1.png"
                        alt="5 Star Review"
                        data-action="https://www.trustpilot.com/reviews/5e3af8693c93ae0b249ea504"
                        className="review-image"
                    />
                </Coverflow>
            </StyleRoot>
        );
    }
}
