import React, { Component } from "react";
import "./Home.css";
import HomeLandingPage from "../components/HomeLandingPage";

export default class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
		};
	}

	componentDidMount = () => {
		window.scrollTo(0, 0);
	};

	renderLandingPage() {
		return (
			<div className="LandingPage">
				<HomeLandingPage />
			</div>
		);
	}

	render() {
		return <main className="Home">{this.renderLandingPage()}</main>;
	}
}
