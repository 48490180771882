import React, { Component /*, Fragment*/ } from "react";
import Media from "react-media";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// Using react containers to achieve responsiveness in Medias
import Container from "react-bootstrap/Container";
import UiLoadBtn from "../components/UiLoadBtn";
import { API, Auth } from "aws-amplify";
import "./ContactWidget.css";
import { withRouter } from "react-router-dom";

class ContactWidget extends Component {
  constructor(props) {
    super(props);
    this.file = null;

    /*
     * The following data will need to be inserted:
     * 1. Customer User name - userName
     * 2. Customer First Name - firstName
     * 3. Customer Last Name - lastName
     * 4. User Email Address - emailAddress
     */

    this.state = {
      isLoading: null,
      focused: false,
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      content: "",
      acctIdUpdateFlag: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  validateForm() {
    return this.state.firstName.length > 0 && this.state.lastName.length > 0;
  }

  handleChange = (event) => {
    //this.getLastNumber();
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isLoading: true,
    });

    // NOTE: Connect to API and persist new records to DB
    // NOTE: Call the inviteNewUser() API here to store
    //       new case records in the db.
    // ss[.]c/c/call-the-create-api.html
    // upload-a-file-tos3.html

    try {
      await this.sendSesMsg({
        title: this.state.title,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        content: this.state.content,
        mobilePhone: "",
        website: "",
      });

      this.props.history.push("/");
    } catch (err) {
      // This comment disables eslint to allow the alert to go thru
      // eslint-disable-next-line
      alert(err);
      this.setState({
        isLoading: false,
      });
    }
  };

  // TODO: get the last item number
  /*
  async getLastNumber(){
    const cases = await API.get("case-records", "/case-records")

    console.log(`This is the variables in promise: ${cases[0].caseRecordId}`)

    return null;
  }
  */

  sendSesMsg(msgData) {
    return API.post("cloud-api", "/contacts/web-contact", {
      body: msgData,
    });
  }

  renderNarrowWidget() {
    return (
      <div className="chrome">
        <Media
          query="(min-width: 319px)"
          render={() => (
            <div>
              <p className="invite-title-xs">
                <strong>Research Request</strong>
              </p>
              <div className="invite-user-container-xs">
                <Form
                  className="user-form"
                  onSubmit={this.handleSubmit.bind(this)}
                >
                  <Container className="user-details-xs" fluid="True">
                    {/* This is the customer email */}
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        as="input"
                        className="input-field"
                        size="lg"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                      />
                      <Form.Text className="text-muted">
                        What is your Email Address?
                      </Form.Text>
                    </Form.Group>

                    {/* This is the user firstName */}
                    <Form.Group controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        as="input"
                        className="input-field"
                        size="lg"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                        required
                      />
                      <Form.Text className="text-muted">
                        What is your First Name?
                      </Form.Text>
                    </Form.Group>

                    {/* This is the user lastName */}
                    <Form.Group controlId="lastName">
                      <Form.Label> Last Name </Form.Label>
                      <Form.Control
                        as="input"
                        className="input-field"
                        size="lg"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        required
                      />
                      <Form.Text className="text-muted">
                        What is your Last Name?
                      </Form.Text>
                    </Form.Group>

                    {/* This is the user title */}
                    <Form.Group controlId="title">
                      <Form.Label>Professional Title </Form.Label>
                      <Form.Control
                        as="input"
                        className="input-field"
                        size="lg"
                        value={this.state.title}
                        onChange={this.handleChange}
                        required
                      />
                      <Form.Text className="text-muted">
                        What is your Professional Title?
                      </Form.Text>
                    </Form.Group>

                    {/* This is the content */}
                    <Form.Group controlId="content">
                      <Form.Label>Contact Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="What Data set do you want me to Research? What questions are you trying to answer from any particular data set that you are working with?"
                        rows="6"
                        className="invite-content"
                        size="lg"
                        value={this.state.content}
                        onChange={this.handleChange}
                      />
                    </Form.Group>

                    {/* UiLoadBtn Component */}
                    <UiLoadBtn
                      block
                      size="lg"
                      disabled={!this.validateForm()}
                      variant="primary"
                      type="submit"
                      isLoading={this.state.isLoading}
                      text="Contact Me"
                      loadingText="Contacting..."
                    />
                  </Container>
                </Form>
              </div>
            </div>
          )}
        />
      </div>
    );
  }

  renderNormalContactWidget() {
    return (
      <div className="chrome">
        <Media
          query="(min-width: 319px) and (max-width: 567px)"
          render={() => (
            <div>
              <p className="invite-title">
                <strong>Contact Me</strong>
              </p>
              <div className="invite-user-container-sm">
                <Form className="user-form" onSubmit={this.handleSubmit}>
                  <Container className="user-details" fluid="True">
                    {/* This is the customer email */}
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        as="input"
                        className="input-field"
                        size="lg"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                      />
                      <Form.Text className="text-muted">
                        What is your Email Address?
                      </Form.Text>
                    </Form.Group>

                    {/* This is the user firstName */}
                    <Form.Group controlId="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        as="input"
                        className="input-field"
                        size="lg"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                        required
                      />
                      <Form.Text className="text-muted">
                        What is your First Name?
                      </Form.Text>
                    </Form.Group>

                    {/* This is the user lastName */}
                    <Form.Group controlId="lastName">
                      <Form.Label> Last Name </Form.Label>
                      <Form.Control
                        as="input"
                        className="input-field"
                        size="lg"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        required
                      />
                      <Form.Text className="text-muted">
                        What is your Last Name?
                      </Form.Text>
                    </Form.Group>

                    {/* This is the user title */}
                    <Form.Group controlId="title">
                      <Form.Label>Professional Title </Form.Label>
                      <Form.Control
                        as="input"
                        className="input-field"
                        size="lg"
                        value={this.state.title}
                        onChange={this.handleChange}
                        required
                      />
                      <Form.Text className="text-muted">
                        What is your Professional Title?
                      </Form.Text>
                    </Form.Group>

                    {/* This is the content */}
                    <Form.Group controlId="content">
                      <Form.Label>Contact Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="What would you like to ask me?"
                        rows="6"
                        className="invite-content"
                        size="lg"
                        value={this.state.content}
                        onChange={this.handleChange}
                      />
                    </Form.Group>

                    {/* UiLoadBtn Component */}
                    <UiLoadBtn
                      block
                      size="lg"
                      disabled={!this.validateForm()}
                      variant="primary"
                      type="submit"
                      isLoading={this.state.isLoading}
                      text="Contact Me"
                      loadingText="Contacting..."
                    />
                  </Container>
                </Form>
              </div>
            </div>
          )}
        />

        <Media
          query="(min-width: 568px)"
          render={() => (
            <div>
              <p className="invite-title">
                <strong>Contact Me</strong>
              </p>
              <div className="invite-user-container-md">
                <Form className="user-form" onSubmit={this.handleSubmit}>
                  <Container className="user-details-md" fluid="True">
                    <Row>
                      <Col>
                        {/* This is the customer email */}
                        <Form.Group controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            as="input"
                            className="input-field"
                            size="lg"
                            value={this.state.email}
                            onChange={this.handleChange}
                            required
                          />
                          <Form.Text className="text-muted">
                            What is your Email Address?
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col>
                        {/* This is the user title */}
                        <Form.Group controlId="title">
                          <Form.Label> Professional Title </Form.Label>
                          <Form.Control
                            as="input"
                            className="input-field"
                            size="lg"
                            value={this.state.title}
                            onChange={this.handleChange}
                            required
                          />
                          <Form.Text className="text-muted">
                            What is your Professional Title?
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {/* This is the customer firstName */}
                        <Form.Group controlId="firstName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            as="input"
                            className="input-field"
                            size="lg"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                            required
                          />
                          <Form.Text className="text-muted">
                            What is your First Name?
                          </Form.Text>
                        </Form.Group>
                      </Col>

                      <Col>
                        {/* This is the customer name */}
                        <Form.Group controlId="lastName">
                          <Form.Label> Last Name </Form.Label>
                          <Form.Control
                            as="input"
                            className="input-field"
                            size="lg"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                            required
                          />
                          <Form.Text className="text-muted">
                            What is your Last Name?
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* This is the content */}
                    <Form.Group controlId="content">
                      <Form.Label>Contact Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="What would you like to ask me?"
                        rows="6"
                        className="invite-content"
                        size="lg"
                        value={this.state.content}
                        onChange={this.handleChange}
                      />
                    </Form.Group>

                    {/* UiLoadBtn Component */}
                    <UiLoadBtn
                      block
                      size="lg"
                      disabled={!this.validateForm()}
                      variant="primary"
                      type="submit"
                      isLoading={this.state.isLoading}
                      text="Contact Me"
                      loadingText="Contacting..."
                    />
                  </Container>
                </Form>
              </div>
            </div>
          )}
        />
      </div>
    );
  }
  render() {
    return this.props.vWidget
      ? this.renderNormalContactWidget()
      : this.renderNarrowWidget();
  }
}

export default withRouter(ContactWidget);
