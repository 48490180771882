import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Media from "react-media";
import ContactWidget from "../containers/ContactWidget";
import Footer from "./Footer";
import "./AiResearch.css";

export default class AiResearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
		};
	}

	componentDidMount = () => {
		window.scrollTo(0, 0);
	};

	render() {
		return (
			<div className="view">
				{/* NOTE: 319px Small Mobile XS-Views!!!*/}
				<Media
					query="(min-width: 319px) and (max-width: 900px)"
					render={() => (
						<Container>
							<Container className="app-view-xs">
								<Container className="main-view-xs">
									<Link
										to="/ai-machine-learning-research/nasa-challenger"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Machine Learning Reasearch
												</h2>
												<h3>
													Predicting Space Shuttle
													Challenger Thermal Distress
													Events
												</h3>
												<p className="feat-abstract">
													<em>Abstract & Task:</em>{" "}
													Predict the number of
													O-rings that experience
													thermal distress on a flight
													at 31 degrees F given data
													on the previous 23 shuttle
													flights.
												</p>

												<p className="feat-abstract">
													The motivation for
													collecting this database was
													the explosion of the USA
													Space Shuttle Challenger on
													28 January, 1986. An
													investigation ensued into
													the reliability of the
													shuttle's propulsion system.
													The explosion was eventually
													traced to the failure of one
													of the three field joints on
													one of the two solid booster
													rockets. Each of these six
													field joints includes two
													O-rings, designated as
													primary and secondary, which
													fail when phenomena called
													erosion and blowby both
													occur.
												</p>
											</Container>
										</Jumbotron>
									</Link>
									<Link
										to="/ai-machine-learning-research/btc-pricing-models"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h3>
													A Machine Learning Model
													using Python & TensorFlow
												</h3>
												<p className="feat-abstract">
													<em>Abstract & Task:</em> An
													implementation of a Machine
													Learning model using
													TensorFlow to describe the
													price of Bitcoin. The study
													quantified 30 predictors to
													develop a multiple linear
													regression to predict the
													market price of Bitcoin via
													Root Mean Square Error
													(RMSE) function. RMSE
													measures the error between
													observed and predicted data.
													The model resulted in an
													RMSE of 802.176, indicating
													that the loss accounts for
													4.1% of the error when
													compared to the observed
													target values. The model
													observed daily pricing and
													feature data to answer the
													question: which predictors
													have a significant impact on
													the price of Bitcoin?
													Consisting of 2,912
													observations the model
													explained 77.2% percent of
													the variation of the
													response variable.
													Corrections for
													multicollinearity took place
													by eliminating Variance
													Inflation Factors greater
													than 5 and selecting
													statistically-significant
													features whose P-Values were
													less than 0.05. The model
													suggested that the features
													associated with market
													demand, number of
													transactions, transaction
													confirmation times,
													transaction USD volume, and
													market demand measured using
													Google Search Trends data,
													account for the prices
													observed in the Bitcoin
													markets today. Faster
													confirmation times, demand
													as measured by daily Google
													searches, and transaction
													volume, explain 77.2% of the
													price of Bitcoin described
													by the R-Squared result.
												</p>
											</Container>
										</Jumbotron>
									</Link>
								</Container>
								<Container className="aside-view-xs">
									<Container className="main-view-xs">
										<Jumbotron
											className="section-heading-xs"
											fluid
										>
											<ContactWidget vWidget={false} />
										</Jumbotron>
									</Container>
								</Container>
							</Container>

							{/* Need the Footer Menu to display new features */}
							<Footer />
						</Container>
					)}
				/>
				{/* NOTE: 1600px Desktop XL-Views!!!*/}
				<Media
					query="(min-width: 901px)"
					render={() => (
						<Container>
							<Container className="app-view">
								<Container className="main-view-ml">
									<Link
										to="/ai-machine-learning-research/nasa-challenger"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h2>
													Machine Learning Reasearch
												</h2>
												<h3>
													Predicting Space Shuttle
													Challenger Thermal Distress
													Events
												</h3>
												<p className="feat-abstract">
													<em>Abstract & Task:</em>{" "}
													Predict the number of
													O-rings that experience
													thermal distress on a flight
													at 31 degrees F given data
													on the previous 23 shuttle
													flights.
												</p>

												<p className="feat-abstract">
													The motivation for
													collecting this database was
													the explosion of the USA
													Space Shuttle Challenger on
													28 January, 1986. An
													investigation ensued into
													the reliability of the
													shuttle's propulsion system.
													The explosion was eventually
													traced to the failure of one
													of the three field joints on
													one of the two solid booster
													rockets. Each of these six
													field joints includes two
													O-rings, designated as
													primary and secondary, which
													fail when phenomena called
													erosion and blowby both
													occur.
												</p>
											</Container>
										</Jumbotron>
									</Link>
									<Link
										to="/ai-machine-learning-research/btc-pricing-models"
										className="feat-link"
									>
										<Jumbotron
											className="section-heading"
											fluid
										>
											<Container>
												<h3>
													A Machine Learning Model
													using Python & TensorFlow
												</h3>
												<p className="feat-abstract">
													<em>Abstract & Task:</em> An
													implementation of a Machine
													Learning model using
													TensorFlow to describe the
													price of Bitcoin. The study
													quantified 30 predictors to
													develop a multiple linear
													regression to predict the
													market price of Bitcoin via
													Root Mean Square Error
													(RMSE) function. RMSE
													measures the error between
													observed and predicted data.
													The model resulted in an
													RMSE of 802.176, indicating
													that the loss accounts for
													4.1% of the error when
													compared to the observed
													target values. The model
													observed daily pricing and
													feature data to answer the
													question: which predictors
													have a significant impact on
													the price of Bitcoin?
													Consisting of 2,912
													observations the model
													explained 77.2% percent of
													the variation of the
													response variable.
													Corrections for
													multicollinearity took place
													by eliminating Variance
													Inflation Factors greater
													than 5 and selecting
													statistically-significant
													features whose P-Values were
													less than 0.05. The model
													suggested that the features
													associated with market
													demand, number of
													transactions, transaction
													confirmation times,
													transaction USD volume, and
													market demand measured using
													Google Search Trends data,
													account for the prices
													observed in the Bitcoin
													markets today. Faster
													confirmation times, demand
													as measured by daily Google
													searches, and transaction
													volume, explain 77.2% of the
													price of Bitcoin described
													by the R-Squared result.
												</p>
											</Container>
										</Jumbotron>
									</Link>
								</Container>
								<Container className="aside-view-ml">
									<Jumbotron
										className="section-heading"
										fluid
									>
										<ContactWidget vWidget={false} />
									</Jumbotron>
								</Container>
							</Container>

							{/* Need the Footer Menu to display new features */}
							<Footer />
						</Container>
					)}
				/>
			</div>
		);
	}
}
