// NOTE: https://api.github.com/users/lopezdp/events

// NOTE: https://api.github.com/users/lopezdp/events

// FIXME: Make this fetch from github

const id = "83f0fc5726168408f5ad";
const secret = "e98470d53d6956dce38cac07763abca4a8155e1e";

export function eventList(usr) {
	return fetch(
		`https://api.github.com/users/${usr}/events?client_id=${id}&client_secret=${secret}`,
		{
			mode: "cors",
			//credentials: "include",
			method: "GET"
		}
	);
}
